export enum AccountOrganizationUnit {
  Label = "label",
  Folder = "folder",
}

export enum AccountSyncState {
  RUNNING = "running",
  PARTIAL = "partial",
  STOPPED = "stopped",
}

export enum MailboxActions {
  SELECTALL = "selectall",
  DELETE = "delete",
  STAR = "star",
  UNREAD = "unread",
}
